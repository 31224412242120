import React, {useContext} from 'react';
import {LocaleContext} from '~src/context';
import locales from '~src/locales';
import GlobalStyle from '~src/theme/global-style';
import {ErrorBoundary} from '~src/components';
import Admin from '~src/components/templates/AdminPage/';
import Providers from '~src/components/Providers';
import Content from '~src/components/organisms/AdminOrganism/Users/Content';

const Users = () => {
  const {
    pages: {admin},
  } = useContext(LocaleContext);
  return (
    <Providers>
      <ErrorBoundary>
        <LocaleContext.Provider value={locales}>
          <GlobalStyle />
          <Admin pageTitle={admin.users.title}>
            <Content />
          </Admin>
        </LocaleContext.Provider>
      </ErrorBoundary>
    </Providers>
  );
};

export default Users;

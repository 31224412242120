import React, {useEffect, useState, useContext} from 'react';

import {Button, Modal, Space, Col, Row, Input, Form} from 'antd';
import {LocaleContext} from '~src/context';
import {Control} from '~src/components';
import {IUser} from '~src/store/Users';

const validateMessages = {
  required: 'Обязательное поле',
  types: {
    email: 'Некорректный email',
  },
};

export interface IUpdatedUser extends Partial<IUser> {
  userPasswordCheck: string;
}

interface Props {
  visible: boolean;
  title: string;
  user: IUser | Partial<IUser>;
  isRequiredPassword: boolean;
  onCancel: () => void;
  onSave: (updatedUser: IUpdatedUser, user: IUser | Partial<IUser>) => void;
}

const UserModal: React.FC<Props> = ({isRequiredPassword, visible, title, onCancel, onSave, user}) => {
  if (!user) return null;

  const {
    components: {modals},
  } = useContext(LocaleContext);
  const [userForm] = Form.useForm();
  const [userName, setUserName] = useState(user.userName);
  const [userEmail, setUserEmail] = useState(user.userEmail);
  const [userPassword, setUserPassword] = useState('');
  const [userPasswordCheck, setUserPasswordCheck] = useState('');
  useEffect(() => {
    setUserName(user.userName);
    setUserEmail(user.userEmail);
    setUserPassword('');
    setUserPasswordCheck('');
  }, [user.userName, user.userEmail, visible]);

  const handleChangeFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetName = e.target.name;
    const value = e.target.value;

    switch (targetName) {
      case 'userName':
        setUserName(value);
        break;
      case 'userEmail':
        setUserEmail(value);
        break;
      case 'userPassword':
        setUserPassword(value);
        break;
      case 'userPasswordCheck':
        setUserPasswordCheck(value);
        break;
    }
  };

  const handleCancel = () => {
    onCancel();
  };
  const handleClickAtCancelButton = () => {
    onCancel();
  };
  const handleClickAtSaveButton = () => {
    userForm.validateFields().then(async () => {
      userForm.submit();
      onSave({userName, userEmail, userPassword, userPasswordCheck}, user);
    });
  };

  return (
    <Modal
      bodyStyle={{minHeight: 175, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width={380}
      title={title}
    >
      <Form form={userForm} initialValues={{userName, userEmail}} validateMessages={validateMessages}>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Row gutter={[0, 0]}>
              <Col span={24}>
                <Control label={modals.user.userName.label}>
                  <Form.Item name="userName" rules={[{required: true}]}>
                    <Input
                      placeholder={modals.user.userName.placeholder}
                      size={'large'}
                      name={'userName'}
                      value={userName}
                      onChange={handleChangeFormValue}
                    />
                  </Form.Item>
                </Control>
              </Col>
              <Col span={24}>
                <Control label={modals.user.userEmail.label}>
                  <Form.Item name="userEmail" rules={[{required: true, type: 'email'}]}>
                    <Input
                      placeholder={modals.user.userEmail.placeholder}
                      size={'large'}
                      name={'userEmail'}
                      value={userEmail}
                      onChange={handleChangeFormValue}
                    />
                  </Form.Item>
                </Control>
              </Col>
              <Col span={24}>
                <Control label={modals.user.userPassword.label}>
                  <Form.Item name="userPassword" rules={[{required: isRequiredPassword}]}>
                    <Input
                      placeholder={modals.user.userPassword.placeholder}
                      size={'large'}
                      name={'userPassword'}
                      type={'password'}
                      value={userPassword}
                      onChange={handleChangeFormValue}
                    />
                  </Form.Item>
                </Control>
              </Col>
              <Col span={24}>
                <Control label={modals.user.userPasswordCheck.label}>
                  <Form.Item
                    name="userPasswordCheck"
                    rules={[
                      ({getFieldValue}) => ({
                        validator(rule, value) {
                          if (getFieldValue('userPassword') !== value) {
                            return Promise.reject('Пароли не совпадают');
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      disabled={!userPassword}
                      placeholder={modals.user.userPasswordCheck.placeholder}
                      size={'large'}
                      name={'userPasswordCheck'}
                      type={'password'}
                      value={userPasswordCheck}
                      onChange={handleChangeFormValue}
                    />
                  </Form.Item>
                </Control>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Space size={10}>
              <Button danger onClick={handleClickAtCancelButton}>
                {modals.user.cancelButton}
              </Button>
              <Button type={'primary'} onClick={handleClickAtSaveButton}>
                {modals.user.saveButton}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UserModal;

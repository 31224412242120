import React, {Fragment, useContext, useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {IUser} from '~src/store/Users';
import {Button, Col, Row, Space, Table, Tag} from 'antd';
import {LocaleContext} from '~src/context';
import isEmpty from 'ramda/es/isEmpty';
import omit from 'ramda/es/omit';
import TableColumnAction from './TableColumnAction';
import {UserRole} from '~src/types';
import UserModal, {IUpdatedUser} from '~src/components/organisms/Modals/UserModal';
import ApproveModal from '~src/components/organisms/Modals/ApproveModal';
import {Routes} from '~src/utils/routes';
import {windowGlobal} from '~src/utils/dom';

export const createUserFields = (): Partial<IUser> => ({
  userName: '',
  userEmail: '',
  userPassword: '',
});

interface IContentProps {}

const Content: React.FC<IContentProps> = () => {
  const {dispatch, users, app} = useStoreon<IState, IEvents>('users', 'app');
  if (['manager'].includes(app.account.role)) {
    if (windowGlobal) {
      navigate(Routes.materials);
    }
  }

  const [selectedRows, selectRows] = useState<IUser[]>([]);
  const [modalMode, setModalMode] = useState<'create' | 'edit'>();
  const [selectedUser, selectUser] = useState<IUser>();
  const [isShowApproveModal, setIsShowApproveModal] = useState(false);

  useEffect(() => {
    dispatch('users/fetch-users', {page: 1, count: 1000, orderBy: 'DESC'});
  }, []);
  const {
    pages: {
      admin: {
        users: {columns, createButton, removeButton, tableColumnActionButton},
      },
    },
    components: {modals},
    maps: {userRoles},
  } = useContext(LocaleContext);

  const handleClickAtCreateButton = () => {
    setModalMode('create');
  };
  const handleClickAtRemoveButton = async () => {
    setIsShowApproveModal(true);
  };
  const handleOkAtApproveModal = () => {
    dispatch('users/delete-users', {usersIds: selectedRows.map(user => user.userId), withFetchUsers: true});
    setIsShowApproveModal(false);
  };
  const handleCancelAtApproveModal = () => {
    setIsShowApproveModal(false);
  };
  const handleChangeRow = (_: any, selectedRows: IUser[]) => {
    selectRows(selectedRows);
  };
  const handleModalSave = ({userPassword, ...userValues}: IUpdatedUser, user: IUser | Partial<IUser>) => {
    setModalMode(undefined);
    selectUser(undefined);
    if (modalMode === 'create') {
      dispatch('users/create-user', {...(userValues as IUser), userPassword: userPassword as string});
    } else if (modalMode === 'edit') {
      const updatingData: Partial<IUser> = {
        userId: user.userId,
        ...userValues,
      };
      if (userPassword) {
        updatingData.userPassword = userPassword;
      }
      dispatch('users/update-user', omit(['iat', 'userPasswordCheck'], updatingData));
    }
  };
  const handleModalCancel = () => {
    setModalMode(undefined);
    selectUser(undefined);
  };
  const handleClickAtTableColumnAction = (user: IUser) => {
    setModalMode('edit');
    selectUser(user);
  };
  const customColumns = columns.map(column => {
    if (column.key === 'actions') {
      return {
        ...column,
        // eslint-disable-next-line react/display-name
        render: (userId: string, value: IUser) => (
          <TableColumnAction user={value} onClick={handleClickAtTableColumnAction}>
            {tableColumnActionButton}
          </TableColumnAction>
        ),
      };
    }
    if (column.key === 'role') {
      // eslint-disable-next-line react/display-name
      return {...column, render: (role: UserRole) => <Tag color={column.colors![role]}>{userRoles[role]}</Tag>};
    }
    return column;
  });

  return (
    <Fragment>
      <Row gutter={[50, 50]}>
        <Col span={24}>
          <Table
            rowKey={'userId'}
            dataSource={users.values}
            columns={customColumns}
            pagination={false}
            rowSelection={{
              onChange: handleChangeRow,
              type: 'checkbox',
            }}
          />
        </Col>
        <Col span={24}>
          <Space>
            <Button danger disabled={isEmpty(selectedRows)} onClick={handleClickAtRemoveButton}>
              {removeButton}
            </Button>
            <Button type={'primary'} onClick={handleClickAtCreateButton}>
              {createButton}
            </Button>
          </Space>
        </Col>
      </Row>
      <UserModal
        visible={Boolean(modalMode)}
        isRequiredPassword={modalMode === 'create'}
        title={modalMode === 'create' ? modals.user.createTitle : modals.user.editTitle}
        onSave={handleModalSave}
        onCancel={handleModalCancel}
        user={modalMode === 'create' ? createUserFields() : selectedUser!}
      />
      <ApproveModal visible={isShowApproveModal} onCancel={handleCancelAtApproveModal} onOk={handleOkAtApproveModal} />
    </Fragment>
  );
};

export default Content;

import React, {Fragment} from 'react';

import {Button} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {IUser} from '~src/store/Users';

interface ITableActionColumnProps {
  href?: string;
  onClick: (user: IUser) => void;
  user: IUser;
}

const TableColumnAction: React.FC<ITableActionColumnProps> = ({href, user, onClick, children}) => {
  const handleClick = () => {
    onClick(user);
  };
  return (
    <Fragment>
      <Button href={href} icon={<EditOutlined />} target={'__blank'} onClick={handleClick}>
        {children}
      </Button>
    </Fragment>
  );
};

export default TableColumnAction;

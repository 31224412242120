import React, {useContext} from 'react';

import {Button, Modal, Space} from 'antd';
import {LocaleContext} from '~src/context';

interface IApproveModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
}

const ApproveModal: React.FC<IApproveModalProps> = ({visible, onCancel, onOk}) => {
  const {
    components: {modals},
  } = useContext(LocaleContext);

  const handleCancel = () => {
    onCancel();
  };
  const handleClickAtCancelButton = () => {
    onCancel();
  };
  const handleClickAtOkButton = () => {
    onOk();
  };

  return (
    <Modal
      bodyStyle={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width={380}
      title={modals.approve.title}
    >
      <Space size={10}>
        <Button onClick={handleClickAtCancelButton}>{modals.approve.cancelButton}</Button>
        <Button type={'primary'} onClick={handleClickAtOkButton}>
          {modals.approve.okButton}
        </Button>
      </Space>
    </Modal>
  );
};

export default ApproveModal;
